<template>
	<div class="">
		<div class=" flex margin-bottom-50 " @click="toDetail">
			<div class="book-cover margin-right-sm">
				<img :src="item.thumb | filterArray">
				<div class="ftag fs12" :class="item.is_free==1 ? 'free_tag' :'fee_tag' ">
					{{item.is_free==1 ? '免费' : '收费'}}
				</div>
			</div>
			<div class="right-info flex flex-direction justify-between w100">
				<div class="flex  justify-between">
					<div class=" flex flex-direction" style="height: 100%;">
						<div class="flex align-center title-box">
							<p class="book-title text-cut">{{item.title}}</p>
							<div class="ftag fs12" :class="item.is_continued==1 ? 'free_tag' :'fee_tag' ">
								{{item.is_continued==1 ? '连载' : '完结'}}
							</div>
						</div>
						<div class="flex align-center margin-top-10">
							<img src="../../assets/image/icon/push.png" class="block-18 margin-right-10">
							<p class="fs12">发布时间：{{item.create_time}}</p>
						</div>
						
					</div>
					<div class="flex flex-direction align-end ">
						<div class="flex align-center text-999 margin-bottom-10 point"  v-if="showCollect" @click.stop="cancelCollect(item.aid)">
							<img src="../../assets/image/icon/collect-empty.png" class="block-18">
							<span class="fs9">{{item.is_collect==1 ? '取消收藏' : '收藏'}}</span>
						</div>
						<!-- 按钮组 -->
						<div class="flex flex-direction handle-btn">
							<el-button type="danger" plain round @click="toCatalog">全部目录</el-button>
							<el-button type="danger" plain round class="margin-top-20">开始阅读</el-button>
						</div>
					</div>
				</div>
				<!-- 内容概述 -->
				<div class="">
					<p class="fs9">内容概述</p>
					<p class="fs9 text-666 margin-top-5">{{item.synopsis}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				lists: [{
						name: ''
					},
					{
						name: ''
					},
					{
						name: ''
					}
				]
			}
		},
		filters:{
			filterArray(val){
				return val[0]
			}
		},
		props:{
			type: [Number, String],
			showCollect:{
				type:Boolean,
				default:true
			},
			item:{
				type:Object,
				default:{}
			}
		},
		methods:{
			toDetail(){
				this.toBlank({
					path:'/fictionDetail',
					query:{
						aid:this.item && this.item.aid
					}
				})
			},
			cancelCollect(aid){
				this.$emit('cancelCollect',this.item)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.book-cover {
		position: relative;
		width: 172px;
		height: 242px;
		background: rgba(0, 0, 0, 0);
		opacity: 1;
		border-radius: 8px 0px 0px 8px;
		flex-shrink: 0;
		img {
			width: 100%;
			height: 100%;
		}
		.ftag {
			position: absolute;
			top: 0;
			right: 0;
			width: 36px;
			text-align: center;
			height: 20px;
			line-height: 20px;
			border-radius: 0 0 0 10px;
			font-weight: bold;
		}
		
		.free_tag {
			color: #31CBA4;
			background: #CAF4D7;
		}
		
		.fee_tag {
			color: #FF7F37;
			background: #FDEDEB;
		}
	}

	.right-info {
		height: 242px;
		.title-box{
			.ftag {
				margin-left: 10px;
				width: 36px;
				text-align: center;
				height: 20px;
				line-height: 20px;
				border-radius: 0 0 0 10px;
				font-weight: bold;
			}
			
			.free_tag {
				color: #31CBA4;
				background: #CAF4D7;
			}
			
			.fee_tag {
				color: #FF7F37;
				background: #FDEDEB;
			}
		}
	}

	.book-title {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		opacity: 1;
	}

	.writer-info {
		.head-img {
			width: 29px;
			height: 29px;
		}

		.left-info {
			.name {
				font-size: 11px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
				opacity: 1;
			}
		}

		.writer-tag {
			p {
				width: 37px;
				height: 12px;
				background: linear-gradient(270deg, #FFA464 0%, #FF762C 100%);
				opacity: 1;
				font-size: 8px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 12px;
				color: #FFFFFF;
				opacity: 1;
			}
		}
	}
	.handle-btn{
		.el-button{
			width: 148px;
			height: 40px;
			line-height: 5px;
			border: 1px solid #E54F42;
			opacity: 1;
			border-radius: 20px;
			font-size: 12px;
		}
		.el-button+.el-button{
			margin-left: 0;
		}
	}
</style>
