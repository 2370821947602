<template>
	<div class="padding-top-20 page_wrap">
		<top :bgWhite='true'></top>
		<div class="contentAll padding-lr-30  top-50 padding-bottom-50">
			<div class="home-top" :style="[{background:'url('+userData.bg_logo+')'}]">
				<!-- <img src="../../../assets/image/center/my_bg.png" width="100%"> -->
				<div class="flex align-center top-con justify-center flex-direction ">
					<div class="head-box">
						<el-image :src="userData.head_img" class="head-img margin-right-xs block-72 radius-72" fit="cover"></el-image>
						<img src="../../../assets/image/icon/bigv.png" class="block-26 bigv" v-if="userData.is_v==1">
					</div>
					<!-- <img :src="userData.head_img" class="block-72 radius-72"> -->
					<p class="padding-top-10 fs18 text-white">{{userData.user_nickname}}</p>
					<span class="fs14 margin-top-10" v-if="userData.tags">{{userData.tags}}</span>
					<div class="padding-lr">
						<span class="fs14 padding-top-10 text-cut" v-if="userData.autograph">{{userData.autograph}}</span>
					</div>
				</div>
				<div class="flex align-center radius-100 point icon1 padding-lr-10 padding-tb-4">
					<el-upload class="avatar-uploader" :action="BaseUrl+'/api/v1/5fc615f7da92b'"
						:show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img src="../../../assets/image/center/upload.png" class="width12 height14">
						<span class="fs14 padding-left-4 text-blue">上传封面图</span>
					</el-upload>
				</div>
			</div>
			<div class="padding-top-20" style="position: relative;">
				<div class="flex align-center padding-lr-200 padding-bottom-15 justify-between solid-bottom">
					<div class="" v-for="(item,index) in navList" :key='index'>
						<span class="margin-right-35 padding-bottom-15  text-666 point    " @click="tabOne(item,index)"
							:class="currentIndex==index?'active_my':''">{{item.name}}</span>
					</div>
				</div>
				<!-- 我的主页 -->
				<div class="flex  justify-between padding-top-20"  v-if="currentIndex==0">
					<div class="home-left">
						<div class="line-eee padding-tb-15 flex align-center justify-around">
							<div class="flex align-center flex-direction justify-center">
								<span class="fs18 bold">{{userData.focus || 0}}</span>
								<span class="padding-top-5 text-3333 fs14">关注</span>
							</div>
							<div class="flex align-center flex-direction justify-center">
								<span class="fs18 bold">{{userData.fans || 0}}</span>
								<span class="padding-top-5 text-3333 fs14">粉丝</span>
							</div>
							<div class="flex align-center flex-direction justify-center">
								<span class="fs18 bold">{{userData.focus || 0}}</span>
								<span class="padding-top-5 text-3333 fs14">作品</span>
							</div>
						</div>
						<div class="line-eee margin-tb-15  flex align-center justify-around">
							<router-link to="/editAccount">
								<div class="flex align-center  padding-tb-15 point  ">
									<img src="../../../assets/image/center/c1.png" class="block-18">
									<span class=" text-333 fs14 padding-left-10">编辑个人资料</span>
								</div>
							</router-link>
						</div>
						<div class="line-eee margin-tb-15  flex align-center justify-around">
							<router-link to="/tag_identity">
								<div class="flex align-center  padding-tb-15 point  ">
									<img src="../../../assets/image/center/shenfen.png" class="block-18">
									<span class=" text-333 fs14 padding-left-10">职业认证</span>
								</div>
							</router-link>
						</div>
						<div class="line-eee margin-tb-15  flex align-center justify-around" v-if="userData.is_writer==1">
							<router-link :to="{path:'/author_intro',query:{user_id:user_id}}">
								<div class="flex align-center  padding-tb-15 point  ">
									<img src="../../../assets/image/center/baike.png" class="block-18">
									<span class=" text-333 fs14 padding-left-10">我的百科</span>
								</div>
							</router-link>
						</div>
						<div class="line-eee margin-tb-15  flex align-center justify-around" @click="toMsg">
							<div class="flex align-center padding-tb-15  point">
								<img src="../../../assets/image/center/c2.png" class="block-18">
								<span class=" text-3333 fs14 padding-left-10">系统通知</span>
							</div>
						</div>
						<div class="line-eee margin-tb-15  flex align-center justify-around width300" @click="toGameCenter">
							<div class="flex align-center  flex-direction  point w100" >
								<img src="../../../assets/image/center/left1.png" class="height185" width="100%">
								<span class=" text-3333 fs14 padding-left-10 padding-tb-10">赛事中心</span>
							</div>
						</div>
						<div class="line-eee margin-tb-15  flex align-center justify-around">
							<div class="flex align-center  flex-direction  point w100" @click="toAuthen">
								<img src="../../../assets/image/center/left2.png" class="height185" width="100%">
								<span class=" text-3333 fs14 padding-left-10 padding-tb-10">作家认证</span>
							</div>
						</div>
						<div class="line-eee margin-tb-15  flex align-center justify-around" @click="toWallet">
							<div class="flex align-center  flex-direction  point w100">
								<img src="../../../assets/image/center/left3.png" class="height185" width="100%">
								<span class=" text-3333 fs14 padding-left-10 padding-tb-10">我的钱包</span>
							</div>
						</div>
						<div class="line-eee margin-tb-15  flex align-center justify-around" @click="toOrder">
							<div class="flex align-center  flex-direction  point w100">
								<img src="../../../assets/image/center/left4.png" class="height185" width="100%">
								<span class=" text-3333 fs14 padding-left-10 padding-tb-10">我的拼书</span>
							</div>
						</div>
					</div>
					<div class="home-right">
						<div class="line-eee padding-tb-15 flex align-center justify-around">
							<div class="padding-lr-20" v-for="(item,index) in tabList" :key='index'>
								<span class=" padding-bottom-19  text-666 point    " @click="tabItem(item,index)"
									:class="tabIndex==index?'active_my':''">{{item.name}}</span>
							</div>
							<div class="bg-f6f7 radius-100 flex align-center search_boxs padding-lr-20 padding-tb-5">
								<input type="text" name="" id="" value="" v-model="keyword" @keyup.enter = "handleSearch" placeholder="搜索我的作品" class="w100"/>
								<span class="el-icon-search text-333 fs16"></span>
								<!-- <div class="confirm-boxs flex align-center justify-center">
								</div> -->
							</div>
						</div>
						<div class="line-eee padding-tb-15 margin-top-15 padding-lr-15">
							<!-- 加载中 -->
							<div class="w100" v-if="!isInit">
								<el-skeleton :rows="12" animated />
							</div>
							<div class="" v-if="isInit && lists.length>0">
								<div class="" v-if="tabIndex!=2">
									<div class="" v-for="(item,index) in lists" :key='index'>
										<my-dynamic-list :is_remind='false' :item="item"  :current='tabIndex+1' @deleteArticle="deleteArticle($event,index)" ></my-dynamic-list>
									</div>
								</div>
								<div class="" v-if="tabIndex==2">
									<my-book-list  :lists='lists' @deleteArticle="deleteArticle($event,index)" @shelfBook="shelfBook"></my-book-list>
								</div>
							</div>
							<div class="" v-if="isInit && lists.length==0">
								<el-empty description="~空空如也~"></el-empty>
							</div>
						</div>
					</div>
				</div>
				<!-- 我的收藏 -->
				<div  v-if="currentIndex==1 || currentIndex==2">
					<my-collect :type="currentIndex" :lists="lists" ref="mycollect" :isInit="isInit" @atypeChange="atypeChange" @handleSearch="collectSearch"></my-collect>
				</div>
			</div>

		</div>
		<bottom></bottom>
	</div>
</template>

<script>
	import myCollect from '@/components/my-collect/index.vue';
	import myDynamicList from '@/components/dynamic/my_dynamic_list';
	import myBookList from '@/components/book-list/my-book-list';
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		components: {
			top,
			bottom,
			myDynamicList,
			myBookList,
			myCollect
		},
		name: 'my',
		data() {
			return {
				keyword:'', //搜索关键词
				isInit:false, //加载中
				BaseUrl:this.imgUrl,
				userData:{},//用户信息
				lists: [],
				navList: [{
					name: '我的主页',
					id: 1,
				}, {
					name: '我的点赞',
					id: 2,
				}, {
					name: '我的足迹',
					id: 3,
				}],
				tabList: [{
					name: '动态',
					id: 1,
				}, {
					name: '文章',
					id: 2,
				}, {
					name: '电子书',
					id: 3,
				}],
				tabIndex: 0, //右侧索引
				user_id:'', //用户id
				page:1,
				currentIndex: 0, //上方索引
				isLoading:false, //是否是加载状态
				imageUrl: require('../../../assets/image/center/my_bg.png') //封面图
			}
		},
		mounted() {
			this.getUserInfo();
			// this.userData = JSON.parse(localStorage.getItem('USER_INFO'))
		},
		methods: {
			shelfBook(){
				this.loadData(3)
			},
			// 删除文章、电子书方法
			deleteArticle($event,index){
				this.$confirm('确认删除?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
				  this.ajax('post', '/v1/6168e28904b32', {
				  	document_id: $event.aid
				  }, res => {
				  	if (res.code == 1) {
				  		this.lists.splice(index,1)
				  		this.$message.success(res.msg);
				  	} else {
				  		this.$message.error(res.msg);
				  	}
				  })
				}).catch(() => {
				  this.$message({
					type: 'info',
					message: '已取消删除'
				  });          
				});
			},
			putawayBook(){
				
			},
			// 搜索事件
			handleSearch(){
				this.loadData()
			},
			// 收藏 足迹搜索
			collectSearch(e){
				this.keyword = e;
				this.loadData(1)
			},
			//  主页  收藏  足迹切换
			tabOne(item, index) {
				if(this.currentIndex == index) return;
				this.currentIndex = index;
				// this.$refs.mycollect.initFun();
				this.isInit = false;
				this.lists = [];
				this.loadData(1)
			},
			// 文章类型切换
			tabItem(item, index) {
				if (this.tabIndex == index) return;
				if(this.isLoading) return;
				this.isInit = false;
				this.lists = [];
				this.tabIndex = index;
				this.loadData(index+1)
			},
			// 我的收藏-切换文章类型
			atypeChange(e){
				console.log(e);
				this.isInit = false;
				this.lists = [];
				this.loadData(e+1)
			},
			// 推荐数据加载
			loadData(article_type) {
				this.isLoading = true;
				let data = {
					article_type:article_type, //1：动态2：文章3：电子书
					// index_type:3 ,//1：关注2：推荐3：热榜
					page:this.page,
					user_id:this.user_id,
					keyword:this.keyword   
				};
				let requestUrl;
				if(this.currentIndex==1){
					data.type = 1;
					requestUrl = '/v1/618dd27ba5e00';
				}else if(this.currentIndex==2){
					data.type = 0;
					requestUrl = '/v1/618dd27ba5e00';
				}else{
					requestUrl = '/v1/613b0f6c07cc9';
				}
				this.ajax('post', requestUrl, data, res => {
					this.isInit = true;
					this.isLoading = false;
					if (res.code == 1) {
						this.totalSize = res.data.total;
						this.lists =  res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
				
			},
			// 获取个人信息
			async getUserInfo() {
				let userinfo = await JSON.parse(localStorage.getItem('USER_INFO'))
				this.ajax('post', '/v1/5c78c4772da97', {
					user_id:userinfo.id
				}, res => {
					if (res.code == 1) {
						this.userData = res.data;
						// todo
						this.user_id = res.data.user_id;
						// this.user_id = 167;
						this.loadData(1)
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 上传成功回调
			handleAvatarSuccess(res, file) {
				this.userData.bg_logo = URL.createObjectURL(file.raw);
				let photo_id = res.data.data.fileId
				this.editUserInfo(photo_id)
			},
			// 修改用户信息
			editUserInfo(photo_id){
				this.ajax('post', '/v1/5cb54af125f1c', {
					bg_logo: photo_id
				}, res => {
					if (res.code == 1) {
						
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 上传封面
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				// const isLt10M = file.size / 1024 / 1024 < 2;

				// // if (!isJPG) {
				// // 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// // }
				// if (!isLt10M) {
				// 	this.$message.error('上传图片大小不能超过 10MB!');
				// }
				// return isLt10M;
			},
			// 跳转赛事中心
			toGameCenter(){
				this.$router.push('/game_center')
			},
			// 跳转我的钱包
			toWallet(){
				this.$router.push('/myWallet')
			},
			// 跳转系统消息
			toMsg(){
				this.$router.push('/system-msg')
			},
			toOrder(){
				this.$router.push('/order-list')
			},
			toAuthen(){
				if(this.userData.is_writer==1){
					this.$message.success('您已经是作家身份啦，无需再次认证');
					return
				}else{
					this.$router.push('/authen')
				}
			}
		}
	}
</script>
<style lang="scss" scoped>
	@import "./my.scss"; 
	.page_wrap{
		background: #FFFFFF !important; 
	}
	.home-top{
		background-size: cover !important;
	}
	.head-box{
		position: relative;
		.bigv{
			position: absolute;
			bottom: 0px;
			right: 5px;
		}
	}
</style>
