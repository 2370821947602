<template>
	<div class="">
		<div class="list-items">
			<div class="flex align-center justify-between" >
				<router-link :to="{path:'/talents_detail',query:{user_id:item.user_id}}">
					<div class="flex align-center writer-info" v-if="item.user" >
						<div class="head-box">
							<el-image :src="item.user.head_img" class="head-img margin-right-xs radius-48 block-48" fit="cover"></el-image>
							<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="item.user.is_v==1">
						</div>
						<!-- <img :src="item.user.head_img" class="head-img margin-right-xs radius-48"> -->
						<div class="left-info">
							<p class="name" >{{item.user.user_nickname}}</p>
							<div class="writer-tag flex align-center margin-top-5" v-if="item.tags_info && item.user.tags_info.length>0 && item.user.tags_info[0]!=''">
								<p type="warning"  v-if="j<2" size="mini" v-for="(ele,j) in item.tags_info" :key="j">{{ele}}</p>
							</div>
						</div>
					</div>
				</router-link>
				<div class="" >
					<span class="time margin-right-xs">{{item.push_time}}</span>
					<el-dropdown trigger="click" v-if="item.user_id!=userInfo.id">
						<span class="el-dropdown-link">
							<i class="el-icon-more point"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item class="clearfix margin-bottom-20">
								<div class="flex align-center"  @click="addFocus">
									<img src="../../assets/image/home/collect-gray.png" class="block-18 margin-right-5">
									<p>{{item.is_focus==1 ? '已关注' :'关注'}}</p>
								</div>
							</el-dropdown-item>
							<el-dropdown-item class="clearfix">
								<router-link :to="{path:'/report',query:{aid:item.aid}}">
									<div class="flex align-center">
										<img src="../../assets/image/home/report.png"  class="block-18 margin-right-5">
										<span class="text-333">举报</span>
									</div>
								</router-link>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<div class="point margin-bottom-15" >
				<div class="inner-content margin-tb-xs" @click='toDetail()'>
					<div class="flex align-center" v-if="current==2">
						<!-- <div class="ftag fs12 margin-right-10" :class="item.is_free==1 ? 'free_tag' :'fee_tag' ">
							{{item.is_free==1 ? '免费' : '收费'}}
						</div> -->
						<p class="bold fs16 margin-tb-20 text-333">{{item.title}}</p>
					</div>
					<!-- <p class="text-666 fs14" style="word-break: break-all;" v-html="item.body" v-if="item.is_free==1 && current==2"></p> -->
					<p class="text-666 fs14" style="word-break: break-all;" v-if="current==1">{{item.body}}</p>
				</div>
				<el-row :gutter="24" v-if="item && item.images && item.is_video==0">
					<el-col :span="8" v-for="(img_item,img_index) in item.images" :key="img_index">
						<el-image :src="img_item" fit="cover" class="w100 height148 radius-6" :preview-src-list="item.images"></el-image>
					</el-col>
				</el-row>
				<el-row :gutter="24" v-if="item && item.images && item.is_video==1">
					<el-col :span="8" v-for="(img_item,img_index) in item.images" :key="img_index">
						<video :src="img_item" controls="controls" width="400" height="">
							当前浏览器不支持 video直接播放，点击这里下载视频： <a :href="img_item">下载视频</a>
						</video>
					</el-col>
				</el-row>
			</div>
			<!-- 底部操作 -->
			<div class="flex align-center justify-around">
				<!-- <div class="point flex align-center" @click="toCollect(item)" >
					<transition :name=" item.is_collect==1 ? 'zoom' : '' " mode="out-in">
						<img src="../../assets/image/icon/collect_fill.png" class="block-22 margin-right-5" v-if="item.is_collect==1" key="collect">
						<img src="../../assets/image/icon/collect.png" class="block-22 margin-right-5" v-else key="uncollect">
					</transition>
					<span class="fs14 " :class="item.is_collect==1 ? 'text-theme' : 'text-999' ">取消收藏</span>
				</div> -->
				<div class="point flex align-center">
					<el-popover
					    placement="bottom"
					    title=""
					    width="200"
					    trigger="hover">
						<div class="point flex align-center" @click="copyUrl(item)">
							<img src="../../assets/image/icon/copy.png" class="block-20 margin-right-10">
							<span class="text-666">复制链接</span>
						</div>
						<div class="flex align-center" slot="reference">
							<img src="../../assets/image/home/share.png" class="block-22 margin-right-5">
							<span class="fs14 text-999">{{item.transmit_num || 0}}</span>
						</div>
					</el-popover>
				</div>
				<div class="point flex align-center" @click.stop='toDetail'>
					<img src="../../assets/image/home/read.png"  class="block-22 margin-right-5">
					<span class="fs14 text-999">{{item.read_num || 0}}</span>
				</div>
				<div class="point flex align-center" @click="giveLike(item)">
					<transition :name=" item.is_like==1 ? 'zoom' : '' " mode="out-in">
						<img src="../../assets/image/home/zan1.png" class="block-22 margin-right-5" v-if="item.is_like==1" key="like">
						<img src="../../assets/image/home/zan.png" class="block-22 margin-right-5" v-else key="unlike">
					</transition>
					<span class="fs14 text-999" :class="item.is_like==1 ? 'text-theme' : 'text-999' ">{{item.like_num || 0}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			// 是否加推荐
			is_remind:Boolean,
			current: [Number, String],
			item:{
				type:Object,
				default:{}
			}
		},
		data(){
			return{
				baseUrl:this.imgUrl
			}
		},
		computed: {
			userInfo() {
				return this.$store.getters.userInfo;
			}
		},
		methods:{
			// 复制链接
			copyUrl(){
				let invitelink = `${this.baseUrl}/index/index/pc/#/artileDetail?article_id=${item.aid}&atype=${this.current+1}`;
				this.$copyText(invitelink).then(
					res => {
						this.$message.success('成功复制到剪贴板')
					},
					err => {
						console.log(err) // 同上
					}
				)
			},
			// 点赞	
			giveLike(){
				this.ajax('post', '/v1/61419389e89d5', {
					article_id: this.item.aid
				}, res => {
					if (res.code == 1) {
						this.item.is_like = this.item.is_like ==1 ? 0 : 1;
						if(this.item.is_like ==1){
							this.item.like_num +=1
						}else{
							this.item.like_num -=1
						}
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 取消收藏
			toCollect(){
				this.$emit('cancelCollect',this.item)
				
			},
			// 加关注
			addFocus(){
				this.ajax('post', '/v1/61419cb4727bd', {
					fuid: this.item.user_id
				}, res => {
					if (res.code == 1) {
						this.item.is_focus = this.item.is_focus ==1 ? 0 : 1;
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			toDetail(){
				// 帖子详情
				this.toBlank({
					path:'/artileDetail',
					query:{
						article_id:this.item && this.item.aid,
						atype:this.current
					}
				});
				return;
				if(this.current==1){
					// /作品详情
					this.$router.push('/worksDetail')
				}else{
					// 帖子详情
					this.$router.push({
						path:'/artileDetail',
						query:{
							article_id:this.item && this.item.aid
						}
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	/** 动画进行时的class **/
	.zoom-enter-active, .zoom-leave-active {
	    transition: all .15s cubic-bezier(0.42, 0, 0.34, 1.55);
	}
	
	/** 设置进场开始的状态和离场结束的状态，都是缩放到0 **/
	.zoom-enter, .zoom-leave-to {
	    transform: scale(0);
	}
	
	/** 设置进场结束的状态和离场开始的状态, 都是缩放到1 **/
	.zoom-enter-to, .zoom-leave {
	    transform: scale(1);
	}
	// 点赞数字+1动画
	.plus-enter-active, .plus-leave-active {
	  transition: all .3s ease-in;
	}
	
	.plus-enter, .plus-leave {
	  transform: translateY(0);
	}
	
	.plus-enter-to, .plus-leave-to {
	  transform: translateY(-17px);
	}
	
	// 点赞数字-1动画
	.minus-enter-active, .minus-leave-active {
	  transition: all .2s ease-in;
	}
	
	.minus-enter {
	  transform: translateY(-34px);
	}
	
	.minus-enter-to {
	  transform: translateY(-17px);
	}
	
	.minus-leave {
	  transform: translateY(0);
	}
	
	.minus-leave-to {
	  transform: translateY(17px);
	}
	.title {
		height: 21px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		line-height: 21px;
		color: #333333;
		opacity: 1;
	}
	.head-box{
		position: relative;
		.bigv{
			position: absolute;
			bottom: 5px;
			right: 5px;
		}
	}
	.ftag {
		width: 36px;
		text-align: center;
		height: 20px;
		line-height: 20px;
		border-radius: 0 8px;
		font-weight: bold;
	}
	
	.free_tag {
		color: #31CBA4;
		background: #CAF4D7;
	}
	
	.fee_tag {
		color: #FF7F37;
		background: #FDEDEB;
	}
	.list-items{
		padding: 20px 0;
		border-bottom: 1px solid #EBEEF5;
	}
	.grid-content{
		img{
			width: 100%;;
		}
	}
	.writer-info {
		.head-img {
			width: 48px;
			height: 48px;
		}

		.left-info {
			.name {
				font-size: 11px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
				opacity: 1;
			}
		}

		.writer-tag {
			p {
				width: 66px;
				height: 20px;
				text-align: center;
				opacity: 1;
				font-size: 8px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 20px;
				color: #FFFFFF;
				opacity: 1;
				&:nth-child(1){
					background: url(../../assets/image/icon/label-bg1.png) no-repeat;
					background-size: cover;
				}
				&:nth-child(2){
					background: url(../../assets/image/icon/label-bg2.png) no-repeat;
					background-size: cover;
				}
			}
		}
	}

	.inner-content {
		p {
			font-size: 9px;
		}
	}
	.time{
		font-size: 8px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 11px;
		color: #999999;
		opacity: 1;
	}
</style>
