<template>
	<div class="">
		<div class=" ">
			<div class="flex align-center margin-bottom">
				<div class=" fs12 margin-right-10 representative_tag" v-if="bookInfo.is_representative==1">
					代表作
				</div>
				<el-select v-model="defaultValue" filterable placeholder="请选择" @change="chooseTab">
					<el-option v-for="(item,index) in lists" :key="index" :label="item.title" :value="item.aid">
					</el-option>
				</el-select>
			</div>
			<div class="flex align-center justify-end">
				<!-- <el-button type="primary" size="small" @click="createNew" class="margin-right-xs">创建作品</el-button> -->
				<el-button type="primary" size="small" @click="setMagnum(bookInfo)" class="margin-right-xs">{{bookInfo.is_representative==1 ? '取消' : '设为'}}代表作</el-button>
				<el-button type="warning" size="small" @click="toSetBook" class="margin-right-xs">作品设置</el-button>
				<el-button type="danger" size="small" @click="putawayBook" class="margin-right-xs">作品{{bookInfo.is_shelf==0 ? '上架' : '下架'}}</el-button>
				<el-button type="success" size="small" @click="createNewCap">新建章节</el-button>
				<el-button icon="el-icon-refresh" circle @click="refreshData"></el-button>
			</div>
		</div>
		<div class="">
			<!-- <div class="" v-for="(item,index) in lists" :key="index">
				<div class="name-box padding-lr-sm cursor text-cut" :class="tab_name==index ? 'name_active' : '' "
					@click="chooseTab(item,index)">{{item.title}}</div>
			</div> -->
			
			<div class="">
				<el-table :data="tableData" style="width: 100%" v-loading="loading">
					<!-- <el-table-column label="序列" >
						<template slot-scope="scope">
							<i class="el-icon-time"></i>
							<span style="margin-left: 10px">{{ scope.row.create_time }}</span>
						</template>
					</el-table-column> -->
					<el-table-column label="章节名称" >
						<template slot-scope="scope"  >
							<p>{{ scope.row.title }}</p>
							<!-- <el-popover trigger="hover" placement="top">
								<p>{{ scope.row.title }}</p>
								<div slot="reference" class="name-wrapper">
									<el-tag size="medium">{{ scope.row.title }}</el-tag>
								</div>
							</el-popover> -->
						</template>
					</el-table-column>
					<el-table-column label="操作"  align="right">
						<template slot-scope="scope">
							<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
							<el-button size="mini" type="warning" @click="handlePush(scope.$index, scope.row)">发布</el-button>
							<el-button size="mini" type="danger" @click="deleteCap(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- <div class=" flex margin-bottom " @click="toDetail">
				<div class="book-cover margin-right-sm flex-shrink ovflowHidden">
					<img :src="item.thumb | filterArray">
					<div class="ftag fs12" :class="item.is_free==1 ? 'free_tag' :'fee_tag' ">
						{{item.is_free==1 ? '免费' : '收费'}}
					</div>
				</div>
				<div class="right-info flex flex-direction justify-between width100">
					<div class="">
						<p class="book-title">{{item.title}}</p>
						<div class="flex align-center padding-top-10">
							<img src="../../assets/image/icon/push.png" class="block-18 margin-right-10">
							<p class="fs12">发布时间：{{item.create_time}}</p>
						</div>
					</div>
					<div class="">
						<div class="inner-content margin-tb-xs">
							<p class="margin-top-10 fs14 text-666">{{item.synopsis}}</p>
						</div>
						<el-row :gutter="24" class="padding-top-10">
							<el-col :span="6">
								<div class="fs14 point text-e54f text-center line-e54f radius-100  padding-tb-5" @click.stop="editBook()">
									编辑
								</div>
							</el-col>
							<el-col :span="6">
								<div class="fs14 text-white text-center point bg-e54f radius-100  padding-tb-5" @click.stop="putawayBook()">
									{{item.is_shelf==0 ? '上架' : '下架'}}
								</div>
							</el-col>
							<el-col :span="6">
								<div class="fs14 point text-999 text-center line-eee radius-100  padding-tb-5" @click.stop="deleteBook()">
									删除
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			lists: {
				type: [Array],
				default: []
			}
		},
		filters: {
			filterArray(val) {
				return val[0]
			}
		},
		data() {
			return {
				bookInfo:{}, //作品信息
				loading:false,
				defaultValue: '',
				aid: '', //电子书id
				tab_name: 0,
				is_shelf: 0, //电子书的状态  is_shelf :0 上架 1下架
				tableData: []
			}
		},
		created() {
			this.aid = this.lists[0].aid;
			this.defaultValue = this.lists[0].title;
			this.bookInfo = this.lists[0];
			this.getLogLists()
		},
		methods: {
			// 设为代表作
			setMagnum(item){
				let is_representative = item.is_representative==1 ? 0 : 1
				this.ajax('post', '/v1/61d6b288c2a57', {
					document_ids:item.aid,
					is_representative:is_representative
				}, res => {
					if (res.code == 1) {
						item.is_representative=is_representative;
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 删除章节
			deleteCap(item){
				this.$confirm('此操作将永久删除该章节, 是否继续?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
				  this.ajax('post', '/v1/61cbcc6bb2407', {
					id: item.id
				  }, res => {
					this.loading = false;
					if (res.code == 1) {
						this.getLogLists()
					} else {
						this.$message.error(res.msg);
					}
							
				  }, err => {
					
				  })
				}).catch(() => {
						   
				});
				
			},
			refreshData(){
				this.loading = true;
				this.getLogLists()
			},
			// 创建新作品
			createNew(){
				this.toBlank({
					path: '/editBook'
				})
			},
			// 作品设置
			toSetBook(){
				this.toBlank({
					path: '/editBook',
					query:{
						aid:this.aid
					}
				})
			},
			// 新建章节
			createNewCap(){
				this.toBlank({
					path: '/addSection',
					query:{
						aid:this.aid,
						is_reset:0 // 编辑
					}
				})
			},
			// 跳转章节编辑
			handleEdit(index, item) {
				console.log(item);
				this.toBlank({
					path: '/addSection',
					query: {
						id: item.id,
						aid: this.aid,
						is_reset:1 ,// 编辑
						is_order:item.is_order
					}
				})
			},
			// 章节发布
			handlePush(index, item) {
				this.ajax('post', '/v1/61c562909ac92', {
					id: item.id || '', //章节id  编辑时传
					document_id: this.aid,
					is_push: 1 ,//0保存，1发布
					is_reset:1 //1  编辑   0 新增
				}, res => {
					if (res.code == 1) {
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 选择电子书
			chooseTab(e) {
				this.loading = true;
				this.aid = e;
				let book_info = this.lists.filter(item=>{
					return item.aid==e
				});
				this.bookInfo = book_info[0]
				this.getLogLists()
			},
			// 获取章节列表
			getLogLists() {
				this.ajax('post', '/v1/61c6b084561cd', {
					page: 1,
					document_id: this.aid,
					order: 'asc'
				}, res => {
					this.loading = false;
					if (res.code == 1) {
						this.totalSize = res.data.total;
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}

				}, err => {
					console.log(err);
					this.hadGotCode = false
				})

			},
			// 跳转详情
			toDetail() {
				this.toBlank({
					path: '/fictionDetail',
					query: {
						aid: this.item && this.item.aid
					}
				})
			},
			//  编辑文章
			editBook() {
				this.toBlank({
					path: '/editBook',
					query: {
						aid: this.item && this.item.aid
					}
				})
			},
			// 61694e83f1fa9  上下架 
			// 6168e28904b32  document_id  删除
			// 上架
			putawayBook() {
				this.ajax('post', '/v1/61694e83f1fa9', {
					id: this.bookInfo && this.bookInfo.aid,
					is_shelf: this.bookInfo.is_shelf == 1 ? 0 : 1
				}, res => {
					if (res.code == 1) {
						this.bookInfo.is_shelf = this.bookInfo.is_shelf == 1 ? 0 : 1
						this.$emit('shelfBook')
						this.$message.success(res.msg);
						this.aid = this.lists[0].aid;
						this.defaultValue = this.lists[0].title;
						this.bookInfo = this.lists[0];
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 删除
			deleteBook() {
				this.$emit('deleteArticle', this.item)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.name-box {
		width: 150px;
		height: 50px;
		line-height: 50px;

	}
	.representative_tag{
		width: 46px;
		text-align: center;
		height: 20px;
		line-height: 20px;
		border-radius: 0 8px;
		font-weight: bold;
		color: #E54F42;
		background: #FDEDEB;
		padding: 2px;
	}
	.name_active {
		color: #000000;
		position: relative;

		&:before {
			content: '';
			color: #F56C6C;
			width: 4px;
			height: 16px;
			margin-top: -8px;
			left: 10px;
			top: 50%;
			position: absolute;
			background: #F56C6C;
		}
	}

	.book-cover {
		position: relative;
		width: 156px;
		height: 220px;
		background: rgba(0, 0, 0, 0);
		border-radius: 8px 0px 0px 8px;
		flex-shrink: 0;

		img {
			width: 100%;
			height: 100%;
		}

		.ftag {
			position: absolute;
			top: 0;
			right: 0;
			width: 36px;
			text-align: center;
			height: 20px;
			line-height: 20px;
			border-radius: 0 0 0 10px;
			font-weight: bold;
		}

		.free_tag {
			color: #31CBA4;
			background: #CAF4D7;
		}

		.fee_tag {
			color: #FF7F37;
			background: #FDEDEB;
		}
	}

	.right-info {
		width: 100%;
	}

	.book-title {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		opacity: 1;
	}

	.writer-info {
		.head-img {
			width: 29px;
			height: 29px;
		}

		.left-info {
			.name {
				font-size: 11px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
				opacity: 1;
			}
		}

		.writer-tag {
			p {
				width: 37px;
				height: 12px;
				background: linear-gradient(270deg, #FFA464 0%, #FF762C 100%);
				opacity: 1;
				font-size: 8px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 12px;
				color: #FFFFFF;
				opacity: 1;
			}
		}
	}
</style>
