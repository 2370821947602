<template>
	<div class="">
		<!-- <p class="title" v-if="is_remind">推荐</p> -->
		<div class="list-items point">
			<div class="flex align-center justify-between">
				<div class="flex align-center writer-info">
					<div class="head-box">
						<el-image :src="item.user.head_img" class="head-img margin-right-xs radius-48 block-48" fit="cover"></el-image>
						<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="item.user.is_v==1">
					</div>
					<!-- <img :src="item.user.head_img" class="block-48 margin-right-xs radius-48"> -->
					<div class="left-info">
						<div class="flex align-center">
							<p class="name" >{{item.user.user_nickname}}</p>
							<div class="writer-tag flex align-center margin-top-5" v-if="item.user.tags_info && item.user.tags_info.length>0 && item.user.tags_info[0]!=''">
								<p type="warning" v-if="j<2" size="mini" v-for="(ele,j) in item.tags_info" :key="j">{{ele}}</p>
							</div>
						</div>
						<span class="time margin-right-xs">{{item.push_time}}</span>
					</div>
				</div>
				<div class="">
					<img src="../../assets/image/center/delet.png" class="block-18 padding-right-15 point" v-if="current==1" @click.stop="deleteArticle(item)">
					<el-dropdown :hide-on-click="true" v-else>
						<span class="el-dropdown-link">
							<i class="el-icon-more point"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item class="clearfix margin-bottom-20">
								<div class="flex align-center" @click.stop="editItem(item)">
									<!-- <img src="../../assets/image/center/edit.png" class="block-18 padding-right-15 point" v-if="current==2" @click.stop="editItem(item)"> -->
									<p class="fs16">编辑</p>
								</div>
							</el-dropdown-item>
							<el-dropdown-item class="clearfix margin-bottom-20">
								<div class="flex align-center" @click.stop="deleteArticle(item)">
									<!-- <img src="../../assets/image/center/delet.png" class="block-18 padding-right-15 point" @click.stop="deleteArticle(item)"> -->
									<p class="fs16">删除</p>
								</div>
							</el-dropdown-item>
							<el-dropdown-item class="clearfix ">
								<div class="flex align-center" @click.stop="setMagnum(item)">
									<!-- <img src="../../assets/image/center/delet.png" class="block-18 padding-right-15 point" @click.stop="deleteArticle(item)"> -->
									<p class="fs16">{{item.is_representative==1 ? '取消' : '设为'}}代表作</p>
								</div>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<div class="" v-if="item.ducoment_type==0">
				<p class="fs16 margin-top-xs">{{item.transmit_desc}}</p>
				<div class="margin-top fs24 bg-gray margin-bottom-sm point" @click="toTransDetail(item)">
					<div class="flex" >
						<div  v-if="item && item.images.length>0" class="images-box" style="flex-shrink: 0;">
							<div  v-for="(img_item,img_index) in item.images" :key="img_index">
								<div class="grid-content bg-purple img-box" v-if="item.is_video==1 && img_index==0">
									<el-image :src="img_item+'?x-oss-process=video/snapshot,t_1000,f_jpg,w_100,h_100,m_fast'" fit="cover" class="width100 height100" :preview-src-list="item.images" style="display: block;"></el-image>
								</div>
								<div class="grid-content bg-purple img-box"  v-if="item.is_video==0 && img_index==0">
									<el-image :src="img_item"  fit="cover" class="width100 height100 " :preview-src-list="item.images" style="display: block;"></el-image>
								</div>
							</div>
						</div>
						<div class=" margin-left writer-info">
							<div class="flex align-center justify-between  padding-top-xs">
								<div class="writer-info flex align-center margin-bottom-xs">
									<div class="head-box fs16 text-theme bold ">
										转自：
									</div>
									<div class="head-box">
										<el-image :src="item.from_user_head_img" class="head-img margin-right-xs radius-48" fit="cover"></el-image>
										<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="item.user.is_v==1">
									</div>
									<div class="left-info">
										<p class="name fs16">{{item.from_user_nickname || '--'}}</p>
									</div>
								</div>
							</div>
							<div class="writer-tag flex align-center margin-top-5" v-if="item.user.tags_info && item.user.tags_info.length>0 && item.user.tags_info[0]!=''">
								<p type="warning"  v-if="j<2" size="mini" class="margin-right-5" v-for="(ele,j) in item.user.tags_info" :key="j">{{ele}}</p>
							</div>
							<div class="fs14 text-666" v-if="item.transmit_type==1 || item.transmit_type==2">
								<div class="flex align-center" v-if="item.transmit_type==2">
									<!-- <div class="ftag fs12 margin-right-10" :class="item.is_free==1 ? 'free_tag' :'fee_tag' ">
										{{item.is_free==1 ? '免费' : '收费'}}
									</div> -->
									<p class="bold fs16 margin-tb-20 text-333">{{item.title}}</p>
								</div>
								<p class="text-2em text-cut " style="height: 36px;line-height: 36px;" v-html="setContent(item.body)" v-if="item.transmit_type==2 && (item.is_buy==1 || item.is_free==1) "></p>
								<p class="text-2em text-cut " style="height: 36px;line-height: 36px;"  v-if="item.transmit_type==1">{{item.body}}</p>
							</div>
							<!-- 电子书 start -->
							<div class="book-area" v-if="item.transmit_type==3">
								<div class="book-list-items padding-top-20 flex margin-bottom point" @click="toDetail(2)">
									<div class="book-cover margin-right-sm">
										<img :src="item.thumb | filterArray">
										<div class="ftag fs12" :class="item.is_free==1 ? 'free_tag' :'fee_tag' ">
											{{item.is_free==1 ? '免费' : '收费'}}
										</div>
									</div>
									<div class="right-info flex flex-direction  justify-around">
										<div class="flex align-center title-box">
											<p class="book-title text-cut">{{item.title}}</p>
											<div class="ftag fs12" :class="item.is_continued==1 ? 'free_tag' :'fee_tag' ">
												{{item.is_continued==1 ? '连载' : '完结'}}
											</div>
										</div>
										<div class="flex align-center writer-info">
											<div class="" style="flex-shrink: 0;">
												<div class="head-box">
													<el-image :src="item.user.head_img" class="head-img margin-right-xs radius-48 block-48" fit="cover"></el-image>
													<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="item.user.is_v==1">
												</div>
												<!-- <img :src="item.user.head_img" class="block-48 radius-48 margin-right-xs"> -->
											</div>
											<div class="left-info">
												<p class="name">{{item.writer_profile}}</p>
												<div class="writer-tag flex align-center margin-top-5" v-if="item.user && item.user.tags_info && item.user.tags_info.length>0 && item.user.tags_info[0]!=''">
													<p type="warning" v-if="j<2" size="mini" class="margin-right-5" v-for="(ele,j) in item.user.tags_info" :key="j">{{ele}}</p>
												</div>
											</div>
										</div>
										<div class="flex align-center">
											<img src="../../assets/image/icon/push.png" class="block-18 margin-right-10">
											<p class="fs12">发布时间：{{item.create_time}}</p>
										</div>
									</div>
								</div>
							</div>
							<!-- 电子书 end -->
						</div>
					</div>
				</div>
			</div>
			<div class=""  v-if="item.ducoment_type==1 || item.ducoment_type==2">
				<div class="inner-content margin-tb-xs" @click='toDetail(item)'>
					<div class="flex align-center" v-if="current==2">
						<div class=" fs12 margin-right-10 representative_tag" v-if="item.is_representative==1">
							代表作
						</div>
						<div class=" fs12 margin-right-10 representative_tag" v-if="item.is_competition==1">
							参赛作品
						</div>
						<div class="ftag fs12 margin-right-10 fee_tag" v-if="item.is_competition!=1 && item.is_free!=1" >
							{{item.is_free==1 ? '免费' : '收费'}}
						</div>
						<p class="bold fs16 margin-tb-20 text-333">{{item.title}}</p>
					</div>
					<p class="margin-top-10 text-666" style="word-break: break-all;"  v-if="current==1">{{item.body}}</p>
				</div>
				<el-row :gutter="24" v-if="item && item.images && item.is_video==0">
					<el-col :span="8" v-for="(img_item,img_index) in item.images" :key="img_index">
						<el-image :src="img_item" fit="cover" class="w100 height148 radius-6" :preview-src-list="item.images"></el-image>
					</el-col>
				</el-row>
				<el-row :gutter="24" v-if="item && item.images && item.is_video==1">
					<el-col :span="8" v-for="(img_item,img_index) in item.images" :key="img_index">
						<video :src="img_item" controls="controls" width="400" height="">
							当前浏览器不支持 video直接播放，点击这里下载视频： <a :href="img_item">下载视频</a>
						</video>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			is_remind: Boolean,
			current: [Number, String],
			item:{
				type:Object,
				default:{}
			}
		},
		data() {
			return {
				lists: [{
						name: ''
					},
					{
						name: ''
					},
					{
						name: ''
					}
				]
			}
		},
		filters:{
			filterArray(val){
				return val[0]
			}
		},
		methods:{
			setContent(res){
				const regex = new RegExp('<img', 'gi')
				return res.replace(regex, `<img style="max-width: 60%; height: auto;display:none"`)
			},
			// 跳转至转发的文章
			toTransDetail(item){
				this.toBlank({
					path:'/artileDetail',
					query: {
						article_id: item.transmit_aid,
						atype: item.transmit_type
					}
				})
			},
			//  编辑文章
			editItem(item){
				this.$router.push({
					path:'/editArticle',
					query:{
						aid:item.aid,
						ducoment_type:this.current+1
					}
				})
			},
			// 设为代表作
			setMagnum(item){
				let is_representative = item.is_representative==1 ? 0 : 1
				this.ajax('post', '/v1/61d6b288c2a57', {
					document_ids:item.aid,
					is_representative:is_representative
				}, res => {
					if (res.code == 1) {
						item.is_representative=is_representative;
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 删除动态
			deleteArticle(item){
				this.$emit('deleteArticle',item)
			},
			toDetail(){
				// 帖子详情
				this.toBlank({
					path:'/artileDetail',
					query:{
						article_id:this.item && this.item.aid,
						atype:this.current
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.title {
		height: 21px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		line-height: 21px;
		color: #333333;
		opacity: 1;
	}
	.images-box{
		width: 100px !important;
		height: 100px;
	}
	.img-box{
		width: 100px !important;
		height: 100px;
		.el-image{
			width: 100px !important;
			height: 100px;
		}
	}
	.ftag {
		width: 36px;
		text-align: center;
		height: 20px;
		line-height: 20px;
		border-radius: 0 8px;
		font-weight: bold;
	}
	.head-box{
		position: relative;
		.bigv{
			position: absolute;
			bottom: 5px;
			right: 5px;
		}
	}
	.free_tag {
		color: #31CBA4;
		background: #CAF4D7;
	}
	.representative_tag{
		min-width: 46px;
		text-align: center;
		height: 20px;
		line-height: 20px;
		border-radius: 0 8px;
		font-weight: bold;
		color: #E54F42;
		background: #FDEDEB;
		padding: 2px;
	}
	.fee_tag {
		color: #FF7F37;
		background: #FDEDEB;
	}
	.list-items {
		padding: 20px 0;
		border-bottom: 1px solid #EBEEF5;
	}

	.grid-content {
		img {
			width: 100%;
			;
		}
	}

	.writer-info {
		.head-img {
			width: 32px;
			height: 32px;
		}

		.left-info {
			.name {
				font-size: 11px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
				opacity: 1;
			}
		}

		.writer-tag {
			p {
				width: 66px;
				height: 20px;
				text-align: center;
				opacity: 1;
				font-size: 8px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 20px;
				color: #FFFFFF;
				opacity: 1;
				&:nth-child(1){
					background: url(../../assets/image/icon/label-bg1.png) no-repeat;
					background-size: cover;
				}
				&:nth-child(2){
					background: url(../../assets/image/icon/label-bg2.png) no-repeat;
					background-size: cover;
				}
			}
		}
	}

	.inner-content {
		p {
			font-size: 9px;
			color: #666666;
		}
	}

	.time {
		font-size: 8px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 11px;
		color: #999999;
		opacity: 1;
	}
	.icon2{
		background: url(../../assets/image/center/icon-bg.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
	// 电子书模块 start
	.book-area{
		.book-cover {
			width: 104px;
			height: 146px;
			background: rgba(0, 0, 0, 0);
			opacity: 1;
			border-radius: 8px 0px 0px 8px;
			position: relative;
			flex-shrink: 0;
			img {
				width: 100%;
				height: 100%;
				border-radius: 8px 0px 0px 8px;
			}
			.ftag {
				position: absolute;
				top: 0;
				right: 0;
				width: 36px;
				text-align: center;
				height: 20px;
				line-height: 20px;
				border-radius: 0 0 0 10px;
				font-weight: bold;
			}
			
			.free_tag {
				color: #31CBA4;
				background: #CAF4D7;
			}
			
			.fee_tag {
				color: #FF7F37;
				background: #FDEDEB;
			}
		}
		.head-box{
			position: relative;
			.bigv{
				position: absolute;
				bottom: 5px;
				right: 5px;
			}
		}
		.right-info {
			height: 146px;
			.title-box{
				.ftag {
					margin-left: 10px;
					width: 36px;
					text-align: center;
					height: 20px;
					line-height: 20px;
					border-radius: 0 0 0 10px;
					font-weight: bold;
				}
				
				.free_tag {
					color: #31CBA4;
					background: #CAF4D7;
				}
				
				.fee_tag {
					color: #FF7F37;
					background: #FDEDEB;
				}
			}
		}
		
		.book-title {
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #333333;
			opacity: 1;
		}
		
		.writer-info {
			.head-img {
				width: 29px;
				height: 29px;
			}
		
			.left-info {
				.name {
					font-size: 11px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #333333;
					opacity: 1;
				}
			}
		
			.writer-tag {
				p {
					width: 66px;
					height: 20px;
					text-align: center;
					opacity: 1;
					font-size: 8px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 20px;
					color: #FFFFFF;
					opacity: 1;
					&:nth-child(1){
						background: url(../../assets/image/icon/label-bg1.png) no-repeat;
						background-size: cover;
					}
					&:nth-child(2){
						background: url(../../assets/image/icon/label-bg2.png) no-repeat;
						background-size: cover;
					}
				}
			}
		}
	}
</style>
