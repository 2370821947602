<template>
	<div>
		<!-- 顶部tab 及搜索栏 -->
		<div class="line-eee padding-tb-10 margin-tb-20 padding-lr-10">
			<el-row :gutter="24">
				<el-col :span="16">
					<div class="flex align-center padding-tb-5 height38" >
						<div class="padding-lr-20" v-for="(item,index) in tabList" :key='index'>
							<span class="text-666 point fs18" @click="tabItem(item,index)" :class="tabIndex==index?'active_collect':''">{{item.name}}</span>
						</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="bg-f6f7 radius-100 flex align-center search_boxs padding-lr-20 padding-tb-5 height38">
						<input type="text" name="" id="" v-model="keyword" value="" :placeholder="type==1 ? '搜索我的收藏' :'搜索我的足迹'" @keyup.enter = "handleSearch" class="w100 fs14"/>
						<span class="el-icon-search text-333 fs16"></span>
						<!-- <div class="confirm-boxs flex align-center justify-center">
						</div> -->
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="w60 line-eee padding-tb-15 margin-top-30 padding-lr-15  ">
			<!-- 加载中 -->
			<div class="w100" v-if="!isInit">
				<el-skeleton :rows="12" animated />
			</div>
			<div class="" v-if="isInit && lists.length>0">
				<div class="" v-if="tabIndex!=2">
					<div class="" v-for="(item,index) in lists" :key='index'>
						<dynamic-collect :is_remind='false' :item="item"  :current='tabIndex+1' @cancelCollect="cancelDynamic($event,index)"></dynamic-collect>
					</div>
				</div>
				<div class="" v-if="tabIndex==2">
					<div v-for="(item,index) in lists" :key='index'>
						<book-collect  :item='item' @cancelCollect="cancelCollectBook($event,index)" :showCollect="type==1 ? true : false"></book-collect>
					</div>
				</div>
			</div>
			<div class="" v-if="isInit && lists.length==0">
				<el-empty description="~空空如也~"></el-empty>
			</div>
		</div>
	</div>
</template>

<script>
	import dynamicCollect from '@/components/dynamic/dynamic_collect.vue';
	import bookCollect from '@/components/book-list/book-collect';
	export default {
		components: {
			dynamicCollect,
			bookCollect
		},
		props:{
			type: [Number, String],
			lists:{
				type:[Array],
				default:[]
			},
			isInit:{
				type:[Boolean],
				default:false
			}
		},
		data(){
			return{
				keyword:'', //关键词
				page:1,
				totalSize:0,//总页数
				tabIndex:0,
				tabList: [{
					name: '动态',
					id: 1,
				}, {
					name: '文章',
					id: 2,
				}, {
					name: '电子书',
					id: 3,
				}],
				bookList: []
			}
		},
		created(){
			// this.loadData()
		},
		methods:{
			// init方法 切换收藏、动态时index 归0
			initFun(){
				this.tabIndex = 0;
			},
			// 取消收藏动态
			cancelDynamic($event,index){
				this.ajax('post', '/v1/6141a0f6bd7d0', {
					article_id: $event.aid,
					type:1
				}, res => {
					if (res.code == 1) {
						this.$message.success(res.msg);
						this.lists.splice(index,1)
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 取消收藏电子书
			cancelCollectBook($event,index){
				this.ajax('post', '/v1/6141a0f6bd7d0', {
					article_id: $event.aid
				}, res => {
					if (res.code == 1) {
						this.$message.success(res.msg);
						this.lists.splice(index,1)
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// tab切换
			tabItem(item, index) {
				if (this.tabIndex == index) return;
				this.tabIndex = index;
				this.$emit('atypeChange',index)
				// this.loadData()
			},
			// 搜索事件
			handleSearch(){
				this.$emit('handleSearch',this.keyword)
			}
		}
	}
</script>

<style scoped lang="scss">
.active_collect {
	position: relative;
	color: #333;
	font-weight: bold;
}

.active_collect::after {
	position: absolute;
	content: '';
	bottom: -24px;
	left: 0;
	right: 0;
	width: 60%;
	margin: auto;
	background-color: #E54F42;
	height: 4px;
	border-radius: 4px;
}
.search_boxs{
	
}
</style>